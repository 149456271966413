<template>
    <div>
        <form>
            <div class="form-group row">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4 ">
                    <label>Group Name <span class="required-indc">*</span></label>                
                    <input type="text" class="reg-field" placeholder="eg: ABC Company">
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4 ">
                    <label>Estimate Number of Members <span class="required-indc">*</span></label>                
                    <input type="text" class="reg-field" placeholder="eg: 10">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4 ">
                    <label>Desired Start Date <span class="required-indc">*</span></label>                
                    <date-picker :lang="lang" input-class="reg-field" placeholder="DD/MM/YYYY"></date-picker>
                </div>
                
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4 ">
                    <label>Group Type <span class="required-indc">*</span></label>            
                    <select name="" class="reg-field">
                        <option value="">Select Grouyp Type</option>
                        <option value="">Group 1</option>
                        <option value="">Group 2</option>
                        <option value="">Group 3</option>
                        <option value="">Group 4</option>
                    </select>
                </div>
            </div>           

            <div class="form-group">
                <div class="mb-4 ">
                    <label>Federal TAX ID Number(TIN/EIN) <span class="required-indc">*</span> <span class="semi-label">Social Security If Partnership</span></label>                
                    <input type="text" class="reg-field" placeholder="eg: Willamsburg">
                </div>
            </div>

            <div class="form-group">
                <div class="mb-4 ">
                    <label>Group Industry <span class="required-indc">*</span></label>            
                    <select name="" class="reg-field">
                        <option value="">Select Industry</option>
                        <option value="">Group 1</option>
                        <option value="">Group 2</option>
                        <option value="">Group 3</option>
                        <option value="">Group 4</option>
                    </select>
                </div>
            </div>

            
            
        </form>
        <div class="action-btn split-action mt-5">
            <router-link to="/registration/broker-info" class="button-black">Back</router-link>
            <div class="action-next">
                <router-link to="/registration/contact-info" class="button-blue">Next</router-link>
                <router-link to="/registration/contact-info" class="button-green">Save & Continue Late</router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'BrokerForm'
}
</script>