<template>
    <div class="registrationWrapper">
        <div class="container">
            <div class="whiteBG-wrap">
                <div class="row">                
                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-7">
                        <div class="regForm-wrap">
                            <h2>Group Information</h2>
                            <hr>
                            <group-form />
                        </div>
                    </div>
                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-5">
                        <group-side-info />
                    </div>                    
                </div>
                <span class="procecss-title">Employer Registration</span>
            </div>
        </div>        
    </div>
</template>
<script>
import GroupForm from '../../components/Registration/GroupInfo/GroupForm.vue'
import GroupSideInfo from '../../components/Registration/GroupInfo/GroupSideInfo.vue'
export default {
    name: 'GroupInfo',
    components: {
        GroupForm,
        GroupSideInfo
    }
}
</script>
<style src="../../assets/css/group-registration.css"></style>